/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Banner} from '@servicetitan/design-system';
import {Link} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    h2: "h2",
    pre: "pre",
    code: "code",
    hr: "hr",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(Banner, {
    status: "warning",
    className: "m-b-2"
  }, "Inline Edit does not work with Select(Semantic), use ", React.createElement(Link, {
    href: "/components/select/"
  }, "beta version of Select"), " for select"), "\n", React.createElement(_components.h1, {
    id: "mode",
    style: {
      position: "relative"
    }
  }, "Mode", React.createElement(_components.a, {
    href: "#mode",
    "aria-label": "mode permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "There are two modes, ", React.createElement(_components.strong, null, "read"), " for displaying the value, and ", React.createElement(_components.strong, null, "edit"), " for enabling control component."), "\n", React.createElement(_components.h2, {
    id: "read-mode",
    style: {
      position: "relative"
    }
  }, "Read mode", React.createElement(_components.a, {
    href: "#read-mode",
    "aria-label": "read mode permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'basic read example'}>\n\t{([submittedValue, setSubmittedValue]) => (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={fieldProps => <Input small {...fieldProps} />}\n\t\t\t\t\tonSave={props => setSubmittedValue(props.value)}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t/>\n\t\t\t</BodyText>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "edit-mode",
    style: {
      position: "relative"
    }
  }, "Edit mode", React.createElement(_components.a, {
    href: "#edit-mode",
    "aria-label": "edit mode permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In edit mode, control can be any component, recommended ones are shown in ", React.createElement(_components.a, {
    href: "#common"
  }, "common examples"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'Edit Example'}>\n\t{([submittedValue, setSubmittedValue]) => (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\t<InlineEdit\n\t\t\t\t\tmode=\"edit\"\n\t\t\t\t\tcontrol={fieldProps => <Input small {...fieldProps} />}\n\t\t\t\t\tonSave={props => setSubmittedValue(props.value)}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t\tautoFocusControl={false}\n\t\t\t\t/>\n\t\t\t</BodyText>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "action",
    style: {
      position: "relative"
    }
  }, "Action", React.createElement(_components.a, {
    href: "#action",
    "aria-label": "action permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Action are default buttons shown in edit mode for ", React.createElement(_components.strong, null, "save"), " and ", React.createElement(_components.strong, null, "cancel"), ". It is displayed by default but it can also be hidden. The ", React.createElement(_components.strong, null, "save"), " and ", React.createElement(_components.strong, null, "cancel"), " function can be triggered using other components, checkout ", React.createElement(_components.a, {
    href: "#none"
  }, "this example"), "."), "\n", React.createElement(_components.h2, {
    id: "layout",
    style: {
      position: "relative"
    }
  }, "Layout", React.createElement(_components.a, {
    href: "#layout",
    "aria-label": "layout permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "inline-default",
    style: {
      position: "relative"
    }
  }, "Inline (default)", React.createElement(_components.a, {
    href: "#inline-default",
    "aria-label": "inline default permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Used when there is adjacent component that should be pushed when actions appear."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'Inline'}>\n\t{([submittedValue, setSubmittedValue]) => (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\tHello this is\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={fieldProps => <Input small {...fieldProps} />}\n\t\t\t\t\tonSave={props => setSubmittedValue(props.value)}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t\tactionLayout=\"inline\"\n\t\t\t\t/>\n\t\t\t\t{` example.`}\n\t\t\t</BodyText>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.p, null, "It can also be used with ", React.createElement(_components.code, null, "width=\"fluid\""), " to fill space."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'Fluid width example'}>\n\t{([submittedValue, setSubmittedValue]) => (\n\t\t<Card>\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={fieldProps => <Input small fluid {...fieldProps} />}\n\t\t\t\t\tonSave={props => setSubmittedValue(props.value)}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t\twidth=\"fluid\"\n\t\t\t\t/>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h3, {
    id: "floating",
    style: {
      position: "relative"
    }
  }, "Floating", React.createElement(_components.a, {
    href: "#floating",
    "aria-label": "floating permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Applies popover to actions. Actions will be absolute positions."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'Floating'}>\n\t{([submittedValue, setSubmittedValue]) => (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\tHello this is\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={fieldProps => <Input small {...fieldProps} />}\n\t\t\t\t\tonSave={props => setSubmittedValue(props.value)}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t\tactionLayout=\"floating\"\n\t\t\t\t/>\n\t\t\t\t{` example.`}\n\t\t\t</BodyText>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h3, {
    id: "none",
    style: {
      position: "relative"
    }
  }, "None", React.createElement(_components.a, {
    href: "#none",
    "aria-label": "none permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Example below shows ", React.createElement(_components.code, null, "actionLayout='none'"), " with custom button inside control for saving."), "\n", React.createElement(_components.p, null, "For more specific use case checkout ", React.createElement(_components.a, {
    href: "#using-custom-read-and-edit-component"
  }, "using custom read and edit component"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'None'}>\n\t{([submittedValue, setSubmittedValue]) => (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\tHello this is\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={(fieldProps, actionProps) => (\n\t\t\t\t\t\t<Input\n\t\t\t\t\t\t\tsmall\n\t\t\t\t\t\t\tplaceholder=\"Enter\"\n\t\t\t\t\t\t\taction={<Button onClick={actionProps.save}>Save</Button>}\n\t\t\t\t\t\t\t{...fieldProps}\n\t\t\t\t\t\t/>\n\t\t\t\t\t)}\n\t\t\t\t\tonSave={props => setSubmittedValue(props.value)}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t\tactionLayout=\"none\"\n\t\t\t\t/>\n\t\t\t\t{` example.`}\n\t\t\t</BodyText>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "direction",
    style: {
      position: "relative"
    }
  }, "Direction", React.createElement(_components.a, {
    href: "#direction",
    "aria-label": "direction permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The placement of the save and cancel buttons can be controlled in the same way ", React.createElement(_components.a, {
    href: "/components/popover/#directions"
  }, "Popover directions"), " are handled. The most common examples are right bottom and bottom left."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Card>\n\t<CardSection>\n\t\t<Stack alignItems=\"space-between\" justifyContent=\"center\">\n\t\t{['rb', 'bl'].map((item, index) => (\n\t\t\t<StackItem key={index} style={{margin: 24, width: 'calc(33% - 48px)'}}>\n\t\t\t\t<InlineEdit\n\t\t\t\t\tmode=\"edit\"\n\t\t\t\t\tcontrol={fieldProps => <TextArea style={{width: 150}} {...fieldProps} />}\n\t\t\t\t\tvalue={item}\n\t\t\t\t\tautoFocusControl={false}\n\t\t\t\t\tactionDirection={item}\n\t\t\t\t/>\n\t\t\t</StackItem>\n\t\t))}\n\t\t</Stack>\n\t</CardSection>\n</Card>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "placeholder",
    style: {
      position: "relative"
    }
  }, "Placeholder", React.createElement(_components.a, {
    href: "#placeholder",
    "aria-label": "placeholder permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In InlineEdit component, there is a default placeholder text is ", React.createElement(_components.code, null, "Enter value"), ". You can override this using ", React.createElement(_components.code, null, "placeholder"), " prop.\nThis placeholder is both used in the read component and the edit component."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'placeholder example'}>\n\t{([submittedValue, setSubmittedValue]) => (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\t<InlineEdit\n\t\t\t\t\tplaceholder=\"Enter value\"\n\t\t\t\t\tcontrol={fieldProps => <Input small {...fieldProps} />}\n\t\t\t\t\tonSave={props => setSubmittedValue(props.value)}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t/>\n\t\t\t</BodyText>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "decoration",
    style: {
      position: "relative"
    }
  }, "Decoration", React.createElement(_components.a, {
    href: "#decoration",
    "aria-label": "decoration permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Card>\n\t<Stack>\n\t\t<StackItem fill style={{flexBasis: '100%'}}>\n\t\t<BodyText el=\"span\">\n\t\t\t<InlineEdit\n\t\t\t\tmode=\"read\"\n\t\t\t\tvalue=\"with decorations\"\n\t\t\t\tcontrol={() => {}}\n\t\t\t\tshowContentOnEdit\n\t\t\t\tactionLayout=\"none\"\n\t\t\t/>\n\t\t</BodyText>\n\t\t</StackItem>\n\t\t<StackItem fill style={{flexBasis: '100%'}}>\n\t\t\t<BodyText el=\"span\">\n\t\t\t<InlineEdit\n\t\t\t\tmode=\"read\"\n\t\t\t\tvalue=\"no icon decoration\"\n\t\t\t\tdecoration={{icon: false}}\n\t\t\t\tcontrol={() => {}}\n\t\t\t\tshowContentOnEdit\n\t\t\t\tactionLayout=\"none\"\n\t\t\t/>\n\t\t</BodyText>\n\t\t</StackItem>\n\t\t<StackItem fill style={{flexBasis: '100%'}}>\n\t\t<BodyText el=\"span\">\n\t\t\t<InlineEdit\n\t\t\t\tmode=\"read\"\n\t\t\t\tvalue=\"no icon, underline\"\n\t\t\t\tdecoration={{icon: false, underline: false}}\n\t\t\t\tcontrol={() => {}}\n\t\t\t\tshowContentOnEdit\n\t\t\t\tactionLayout=\"none\"\n\t\t\t/>\n\t\t</BodyText>\n\t\t</StackItem>\n\t\t<StackItem fill style={{flexBasis: '100%'}}>\n\t\t<BodyText el=\"span\">\n\t\t\t<InlineEdit\n\t\t\t\tmode=\"read\"\n\t\t\t\tvalue=\"no icon, underline, color\"\n\t\t\t\tdecoration={{icon: false, underline: false, color: false}}\n\t\t\t\tcontrol={() => {}}\n\t\t\t\tshowContentOnEdit\n\t\t\t\tactionLayout=\"none\"\n\t\t\t/>\n\t\t</BodyText>\n\t\t</StackItem>\n\t</Stack>\n</Card>\n")), "\n", React.createElement(_components.h2, {
    id: "proximity-and-density",
    style: {
      position: "relative"
    }
  }, "Proximity, and Density", React.createElement(_components.a, {
    href: "#proximity-and-density",
    "aria-label": "proximity and density permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Decorations are used to draw attention to users for click action and when there are too many items fighting for attention, it becomes a distraction - when page has multiple Inline Edit components, be conservative of the decorations."), "\n", React.createElement(_components.p, null, "When the InlineEdit component is lower than tertiary action, do not use decorations."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "examples",
    style: {
      position: "relative"
    }
  }, "Examples", React.createElement(_components.a, {
    href: "#examples",
    "aria-label": "examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "common",
    style: {
      position: "relative"
    }
  }, "Common", React.createElement(_components.a, {
    href: "#common",
    "aria-label": "common permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "using-in-headline",
    style: {
      position: "relative"
    }
  }, "Using in Headline", React.createElement(_components.a, {
    href: "#using-in-headline",
    "aria-label": "using in headline permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'Headline Example'}>\n\t{([submittedValue, setSubmittedValue]) => (\n\t\t<Card>\n\t\t\t<Headline el=\"span\">\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={fieldProps => <Input placeholder=\"Enter\" {...fieldProps} />}\n\t\t\t\t\tonSave={props => setSubmittedValue(props.value)}\n\t\t\t\t\tdecoration={{color: false, underline: false}}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t/>\n\t\t\t</Headline>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h3, {
    id: "input-as-control",
    style: {
      position: "relative"
    }
  }, "Input as control", React.createElement(_components.a, {
    href: "#input-as-control",
    "aria-label": "input as control permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'Input Example'}>\n\t{([submittedValue, setSubmittedValue]) => (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={fieldProps => <Input placeholder=\"Enter\" {...fieldProps} />}\n\t\t\t\t\tonSave={props => setSubmittedValue(props.value)}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t/>\n\t\t\t</BodyText>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h3, {
    id: "textarea-as-control",
    style: {
      position: "relative"
    }
  }, "TextArea as control", React.createElement(_components.a, {
    href: "#textarea-as-control",
    "aria-label": "textarea as control permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'TextArea Example'}>\n\t{([submittedValue, setSubmittedValue]) => (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={fieldProps => <TextArea placeholder=\"Enter\" {...fieldProps} />}\n\t\t\t\t\tonSave={props => setSubmittedValue(props.value)}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t/>\n\t\t\t</BodyText>\n\t\t</Card>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h3, {
    id: "select-as-control",
    style: {
      position: "relative"
    }
  }, "Select as control", React.createElement(_components.a, {
    href: "#select-as-control",
    "aria-label": "select as control permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst CommonSelect = () => {\n\tconst dummyOptions = [\n\t\t{ text: 'Jane Doe', value: 1 },\n\t\t{ text: 'Bob Ross', value: 2 },\n\t\t{ text: 'Jackie Robinson', value: 3 },\n\t\t{ text: 'Alexandria Garcia', value: 4 },\n\t\t{ text: 'Zack Bower', value: 5 },\n\t\t{ text: 'Erin Smith', value: 6 },\n\t\t{ text: 'Jarrod Saltalamacchia', value: 7 },\n\t\t{ text: 'Natalia McPhearson', value: 8 }\n\t];\n\tconst value = dummyOptions[0];\n\tconst [submittedValue, setSubmittedValue] = React.useState(value);\n\tconst onSave = props => setSubmittedValue(props.value);\n\tconst getControl = fieldProps => (\n\t\t<AnvilSelect\n\t\t\ttrigger={{\n\t\t\t\tsize: 'small',\n\t\t\t}}\n\t\t\toptions={dummyOptions}\n\t\t\t{...fieldProps}\n\t\t/>\n\t);\n\n\treturn (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={getControl}\n\t\t\t\t\tonSave={onSave}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t/>\n\t\t\t</BodyText>\n\t\t</Card>\n\t);\n};\nrender (<CommonSelect />);\n")), "\n", React.createElement(_components.h3, {
    id: "select-multiple-as-control",
    style: {
      position: "relative"
    }
  }, "Select multiple as control", React.createElement(_components.a, {
    href: "#select-multiple-as-control",
    "aria-label": "select multiple as control permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst CommonSelectMultiple = () => {\n\tconst dummyOptions = [\n\t\t{ text: 'Jane Doe', value: 1 },\n\t\t{ text: 'Bob Ross', value: 2 },\n\t\t{ text: 'Jackie Robinson', value: 3 },\n\t\t{ text: 'Alexandria Garcia', value: 4 },\n\t\t{ text: 'Zack Bower', value: 5 },\n\t\t{ text: 'Erin Smith', value: 6 },\n\t\t{ text: 'Jarrod Saltalamacchia', value: 7 },\n\t\t{ text: 'Natalia McPhearson', value: 8 }\n\t];\n\tconst value = [dummyOptions[0], dummyOptions[3], dummyOptions[4], dummyOptions[6]];\n\tconst [submittedValue, setSubmittedValue] = React.useState(value);\n\tconst onSave = props => setSubmittedValue(props.value);\n\tconst getControl = fieldProps => (\n\t\t<AnvilSelect\n\t\t\tmultiple\n\t\t\ttrigger={{\n\t\t\t\tsize: 'small',\n\t\t\t}}\n\t\t\toptions={dummyOptions}\n\t\t\t{...fieldProps}\n\t\t/>\n\t);\n\n\treturn (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={getControl}\n\t\t\t\t\tonSave={onSave}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t\tdecoration={{underline: false}}\n\t\t\t\t/>\n\t\t\t</BodyText>\n\t\t</Card>\n\t);\n};\nrender (<CommonSelectMultiple />);\n")), "\n", React.createElement(_components.h2, {
    id: "custom",
    style: {
      position: "relative"
    }
  }, "Custom", React.createElement(_components.a, {
    href: "#custom",
    "aria-label": "custom permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "You can add custom content and complex control for the InlineEdit."), "\n", React.createElement(_components.h3, {
    id: "using-custom-read-component",
    style: {
      position: "relative"
    }
  }, "Using custom read component", React.createElement(_components.a, {
    href: "#using-custom-read-component",
    "aria-label": "using custom read component permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst OverridingContent = () => {\n\tconst value = 'Custom Read mode';\n\tconst [submittedValue, setSubmittedValue] = React.useState(value);\n\tconst onSave = props => setSubmittedValue(props.value);\n\tconst getControl = fieldProps => <Input placeholder=\"Enter\" {...fieldProps} />;\n\tconst getContent = () => <TagGroup><Tag>{submittedValue}</Tag></TagGroup>;\n\n\treturn (\n\t\t<Card>\n\t\t\t<BodyText el=\"span\">\n\t\t\t\t<InlineEdit\n\t\t\t\t\tcontrol={getControl}\n\t\t\t\t\tonSave={onSave}\n\t\t\t\t\tvalue={submittedValue}\n\t\t\t\t\tcontent={getContent}\n\t\t\t\t/>\n\t\t\t</BodyText>\n\t\t</Card>\n\t);\n};\nrender (<OverridingContent />)\n")), "\n", React.createElement(_components.h3, {
    id: "using-custom-read-and-edit-component",
    style: {
      position: "relative"
    }
  }, "Using custom read and edit component", React.createElement(_components.a, {
    href: "#using-custom-read-and-edit-component",
    "aria-label": "using custom read and edit component permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst CustomModalExample = () => {\n\tconst [savedValue, setSavedValue] = React.useState(['Bob']);\n\tconst [value, setValue] = React.useState(savedValue);\n\tconst [open, setOpen] = React.useState(false);\n\n\tconst handleSave = () => {\n\t\tsetSavedValue(value);\n\t\tsetOpen(false);\n\t};\n\n\tconst handleOnChange = (value, checked) => {\n\t\tif(checked){\n\t\t\tsetValue(prevState => [...prevState, value])\n\t\t} else {\n\t\t\tsetValue(prevState => [...prevState].filter(item => item !== value))\n\t\t}\n\t};\n\n\tconst handleOnClick = () => {\n\t\tsetOpen(true);\n\t};\n\n\tconst handleCancel = () => {\n\t\tsetValue(savedValue);\n\t\tsetOpen(false);\n\t};\n\n\tconst getContent = () => {\n\t\treturn (\n\t\t\t<TagGroup>\n\t\t\t\t{savedValue.map((item, i) => {\n\t\t\t\t\treturn (\n\t\t\t\t\t\t<Tag key={i}>{item}</Tag>\n\t\t\t\t\t);\n\t\t\t\t})}\n\t\t\t</TagGroup>\n\t\t);\n\t};\n\n\tconst getControl = () => {\n\t\treturn (\n\t\t\t<Modal\n\t\t\t\topen={open}\n\t\t\t\tclosable\n\t\t\t\tonClose={handleCancel}\n\t\t\t\ttitle=\"Test Input in Modal\"\n\t\t\t\tfooter={(\n\t\t\t\t\t<ButtonGroup>\n\t\t\t\t\t\t<Button onClick={handleCancel}>Cancel</Button>\n\t\t\t\t\t\t<Button onClick={handleSave} primary>Save</Button>\n\t\t\t\t\t</ButtonGroup>\n\t\t\t\t)}\n\t\t\t>\n\t\t\t\t<Form>\n\t\t\t\t\t<Form.Checkbox onChange={handleOnChange} label=\"Bob\" value=\"Bob\" checked={value.includes('Bob')}/>\n\t\t\t\t\t<Form.Checkbox onChange={handleOnChange} label=\"Jane\" value=\"Jane\" checked={value.includes('Jane')}/>\n\t\t\t\t</Form>\n\t\t\t</Modal>\n\t\t);\n\t};\n\n\treturn (\n\t\t<Card>\n\t\t\t<InlineEdit\n\t\t\t\tmode={open ? 'edit' : 'read'}\n\t\t\t\tcontrol={getControl}\n\t\t\t\tonClick={handleOnClick}\n\t\t\t\tactionLayout=\"none\"\n\t\t\t\tcontent={getContent}\n\t\t\t\tshowContentOnEdit\n\t\t\t/>\n\t\t</Card>\n\t);\n};\nrender (CustomModalExample)\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use to update a single pre-existing data."), "\n", React.createElement(_components.li, null, "When editing content is a primary task, use form instead."), "\n", React.createElement(_components.li, null, "Do not use InlineEdit and other form controls in the same form."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-components",
    style: {
      position: "relative"
    }
  }, "Related Components", React.createElement(_components.a, {
    href: "#related-components",
    "aria-label": "related components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Typography components used in the examples: ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/typography/bodytext/"
  }, "BodyText")), ", ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/typography/headline/"
  }, "Headline"))), "\n", React.createElement(_components.li, null, "Controls show on the examples: ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/input/"
  }, "Input")), ", ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/textarea/"
  }, "TextArea")), ", ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/modal/"
  }, "Modal")), ", ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/select/"
  }, "Select Beta")), ", ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/checkbox/"
  }, "Checkbox"))), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
